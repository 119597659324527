.App {
  width: calc(100vw - 24px);
  height: calc(100vh - 24px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px;
}

.block {
  display: inherit;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
}

h1 {
  color: black;
  font-size: 60px;
  margin-bottom: 8px;
  text-align: center;
}

h2 {
  color: rgb(104, 112, 118);
  font-size: 24px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 400;
}

img {
  max-width: 60%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 4px;
}

.button-84 {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(#464d55, #25292e);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 18px;
  height: 52px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-84:hover {
  box-shadow: rgba(0, 1, 0, 0.2) 0 2px 8px;
  opacity: 0.85;
}

.button-84:active {
  outline: 0;
}

.button-84:focus {
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 0 3px;
}

@media (max-width: 420px) {
  .button-84 {
    height: 48px;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
  }
}

.buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

a {
  text-decoration: none;
}
